<script setup>
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";
// import { useAuthStore } from "../stores/auth.store";
import { useCalendarStore } from "../stores/calendar";


const { etapes, loading, error, getActiveEtape } = storeToRefs(useCalendarStore());

</script>

<template>
  <div class="post">
    <div v-if="loading" class="loading">loading</div>
    <p v-if="error">{{ error.message }}</p>

    <div class="flex-column-gap-10 rounded-30 bg-grey-04 padding-10">

      <div class="flex-gap-center-middle-20 padding-20 rounded"  v-if="getActiveEtape?.dateRDV == null">
        <p class="weight-600">
          À faire entre le {{ $filters.formatDate(getActiveEtape?.cibleMin) }} et le
          {{ $filters.formatDate(getActiveEtape?.cibleMax) }}
        </p>
      </div>
      <div class="flex-gap-center-middle-20 padding-20 rounded" v-if="getActiveEtape?.statutEtapeIDEid != 0">
        <p class="weight-600">
          Déjà effectué !
        </p>
      </div>

      <router-link to="/rdv" v-if="getActiveEtape?.statutEtapeIDEid == 0">
        <div :class="
          'action-' +
          $filters.getCssStatus(getActiveEtape?.actes[0].status.state) +
          ' flex-middle-gap-10 height-70 padding-10 rounded'
        ">
          <i class="icon fas fa-home-alt"></i>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[0].status.state < 0">
            <span class="weight-600">Rendez-vous pris</span><br />
            <span class="weight-500 font-xs">
              le {{ $filters.formatDate(getActiveEtape?.dateRDV) }}</span>
          </p>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[0].status.state >= 0">
            <span class="weight-600">Rendez-vous à indiquer</span><br />
            <span class="weight-500 font-xs">entre le {{ $filters.formatDate(getActiveEtape?.cibleMin) }} et le
              {{ $filters.formatDate(getActiveEtape?.cibleMax) }}</span>
          </p>
        </div>
      </router-link>
      <div v-else :class="
          'action-' +
          $filters.getCssStatus(getActiveEtape?.actes[0].status.state) +
          ' flex-middle-gap-10 height-70 padding-10 rounded'
        ">
          <i class="icon fas fa-home-alt"></i>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[0].status.state < 0">
            <span class="weight-600">Rendez-vous pris</span><br />
            <span class="weight-500 font-xs">
              le {{ $filters.formatDate(getActiveEtape?.dateRDV) }}</span>
          </p>
          <div class="flex-column-middle-center-gap-5 width-40">
            <i class="far fa-thumbs-up font-xl"></i>
          </div>
        </div>

      <router-link to="/traitement" v-if="getActiveEtape?.statutEtapeIDEid == 0">
        <div :class="
          'action-' +
          $filters.getCssStatus(getActiveEtape?.actes[1].status.state) +
          ' flex-middle-gap-10 height-70 padding-10 rounded'
        ">
          <i class="icon fas fa-prescription-bottle-alt"></i>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[1].status.state < 0">
            <span class="weight-600">Traitement récupéré</span><br />
            <span class="weight-500 font-xs">
              le {{ $filters.formatDate(getActiveEtape?.actes[1].dateComplete) }}</span>
          </p>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[1].status.state >= 0">
            <span class="weight-600">Traitement à récupérer</span><br />
            <span class="weight-500 font-xs" v-if="getActiveEtape?.dateRDV">avant le {{ $filters.formatDate(getActiveEtape?.dateRDV) }}</span>
          </p>
          <div class="mark flex-column-middle-center-gap-5 width-40" target-modal="action-menu">
            <i class="far fa-ellipsis-v font-xl"></i>
          </div>
        </div>
      </router-link>
      <div v-else 
          :class="
          'action-' +
          $filters.getCssStatus(getActiveEtape?.actes[1].status.state) +
          ' flex-middle-gap-10 height-70 padding-10 rounded'
        ">
          <i class="icon fas fa-prescription-bottle-alt"></i>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[1].status.state < 0">
            <span class="weight-600">Traitement récupéré</span><br />
            <span class="weight-500 font-xs">
              le {{ $filters.formatDate(getActiveEtape?.actes[1].dateComplete) }}</span>
          </p>
          <div class="flex-column-middle-center-gap-5 width-40">
            <i class="far fa-thumbs-up font-xl"></i>
          </div>
        </div>

      <router-link to="/injection" v-if="getActiveEtape?.statutEtapeIDEid == 0">
        <div :class="
          'action-' +
          $filters.getCssStatus(getActiveEtape?.actes[2].status.state) +
          ' flex-middle-gap-10 height-70 padding-10 rounded'
        ">
          <i class="icon fas fa-syringe"></i>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[2].status.state < 0">
            <span class="weight-600">Acte confirmé</span><br />
            <span class="weight-500 font-xs">le {{ $filters.formatDate(getActiveEtape?.actes[2].dateComplete) }}</span>
          </p>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[2].status.state >= 0">
            <span class="weight-600">Acte à confirmer</span><br />
            <span class="weight-500 font-xs"  v-if="getActiveEtape?.dateRDV">le {{ $filters.formatDate(getActiveEtape?.dateRDV) }}</span>
          </p>
          <div class="mark flex-column-middle-center-gap-5 width-40" target-modal="action-menu">
            <i class="far fa-ellipsis-v font-xl"></i>
          </div>
        </div>
      </router-link>
      <div v-else
          :class="
          'action-' +
          $filters.getCssStatus(getActiveEtape?.actes[2].status.state) +
          ' flex-middle-gap-10 height-70 padding-10 rounded'
        ">
          <i class="icon fas fa-syringe"></i>
          <p class="flexchild-100" v-if="getActiveEtape?.actes[2].status.state < 0">
            <span class="weight-600">Acte confirmé</span><br />
            <span class="weight-500 font-xs">le {{ $filters.formatDate(getActiveEtape?.actes[2].dateComplete) }}</span>
          </p>
          <div class="flex-column-middle-center-gap-5 width-40">
            <i class="far fa-thumbs-up font-xl"></i>
          </div>
        </div>


              <!-- <div class="flex-column-gap" v-if="getActiveEtape?.isComplete && getActiveEtape?.statutEtapeIDEid == 0">
                <div class="flex-column-middle-center-gap-20" style="margin-top:20px;"><span class="weight-600">Voici le code à scanner par votre infirmière :   </span></div>
                <img style="width:100%" id="qrCode" :src="getQrCodeString(getActiveEtape?.etapeGuid)" />
              </div>
          </div> -->

          <div class="flex-column-gap">
            <div class="flex-column-middle-center-gap-20" style="margin-top:20px;">
              <span class="weight-600">QR code infirmier</span>
            </div>
          </div>
        <div class="flex-column-gap" v-if="getActiveEtape?.shouldDisplayQrCode || getActiveEtape?.isComplete && getActiveEtape?.statutEtapeIDEid == 0">
          <div class="flex-column-middle-center-gap-20" style="margin-top:20px;">
            <span class="weight-100" style="font-size:12px"><i>(Maintenez pour afficher en plein écran)</i></span>
            <span class="weight-100" style="font-size:12px">{{ getQrCodeUrl(getActiveEtape?.etapeGuid) }}</span>
          </div>
        </div>
        <div class="flex-column-gap">
          <div class="flex-column-middle-center-gap-20" style="margin-top:20px;">
            <img style="width:80%" id="qrCode" :src="getQrCodeString(getActiveEtape?.etapeGuid)" />
          </div>
        </div>

      </div>

      <div class="flex-column-gap" v-if="getActiveEtape?.statutEtapeIDEid != 0">
          <div class="flex-column-middle-center-gap-20" style="margin-top:20px;"><span class="weight-600">L'acte a été confirmé par un professionnel de santé.</span></div>
      </div>


  </div>
</template>
<script>

import api from "../services/api";

export default defineComponent({
  data() {
    return {
      // loading: false,
      // post: null,
      // authStore:null,
      // calendarStore:null,
      qrCodeData: ''
    };
  },
  watch: {
    //'calendar':'setCurrentEtape'
  },
  components: {},
  created() {
    // this.calendarStore = useCalendarStore();
    // this.authStore = useAuthStore();
    // this.calendarStore.fetchCalendar();
    // // fetch the data when the view is created and the data is
    // // already being observed
    // this.fetchData();
  },
  mounted() {
    
    // api.get('/calendar/getQrCode2?eid=' + this.getActiveEtape?.etapeGuid).then(response =>  {
    //   this.qrCodeData = response.data;
    //   console.log(this.qrCodeData);
    // }); //TODO : Catch les erreurs
  },
  methods: {  
    getQrCodeString(eid) {
      let ref = this;
      ref.hasError = false;

      api.get('/calendar/getQrCode2?eid=' + eid).then(response =>  {
        this.qrCodeData = response.data;
      })
      .catch(function (error) {
                    ref.hasError = true;
                    ref.errorMessage = error.message;

                    if (error.response) {
                        console.log("Error", error.message);
                        ref.errorMessage = error.response.data.errors;
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                });   

    return this.qrCodeData;

    },

    getQrCodeUrl(eid) {

      return  process.env.VUE_APP_BACKEND + "/QRCodeIDE?eid=" + eid;
    },

    setCurrentEtape() {
      //getActiveEtape = etapes[0];
    },
    fetchData() {
      // let ref= this;

      // this.post = null;
      // this.loading = true;

      //this.calendar = this.calendarStore.etapes;
      document.getElementById("appcontent")?.setAttribute("state", "default");

      // api
      // .get("/calendar/GetCalendar",
      //  { params: {
      //     typeCohorteId: 1,
      //     calendrierId: 3
      //   }}
      // )
      //   //.then(r => r.json())
      //   .then(json => {
      //     console.log('axios get result', json);
      //       ref.calendar = json.data;
      //       ref.loading = false;

      //       document.getElementById('appcontent')?.setAttribute('state', 'default');

      //       return;
      //   });
    },
  },
});
</script>
